<template>
  <div>
    <div class="row">
      <tickets-list :stats="false" :defaultQuery="query" title="Relaterade ärenden"></tickets-list>
    </div>
  </div>
</template>
<script>
import TicketsList from '@/components/lists/ticket/TicketsList.vue';

export default {
  components: {
    TicketsList,
  },
  props: {
    ticket: Object,
  },
  data() {
    return {
      query: this.defineQuery(),
    };
  },

  methods: {
    defineQuery() {
      if (this.ticket.related_tickets === null) {
        return {
          query: {
            bool: {
              should: [
                {
                  match: {person_id: this.ticket.person.id},
                },
              ],
              minimum_should_match: '1',
            },
          },
        };
      } else {
        return {
          query: {
            bool: {
              should: [
                {
                  terms: {
                    _id: this.ticket.related_tickets.split(',').map(function (item) {
                      return item.trim();
                    }),
                  },
                },
                {
                  match: {person_id: this.ticket.person.id},
                },
              ],
              minimum_should_match: '1',
            },
          },
        };
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  mounted() {
  },
};
</script>
